import React, { useEffect, useRef } from 'react';
import { motion, useInView, useAnimation } from 'framer-motion';
import SlideAfterMotion from '@/components/common/animation/SlideAfterMotion';

const ChooseUsSection = ({ chooseUsItem }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.4,
        staggerChildren: 0.3,
      },
    },
  };

  const item1 = {
    hidden: { y: 50, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };


  return (
    <div className="choose_us_section_wrapper">
      <div className="container">
        <SlideAfterMotion>
          <div className="choose_us_title_wrapper">
            <h1 className="choose_us_title">
              Why <b className="color_title">Choose Us</b>
            </h1>
          </div>
        </SlideAfterMotion>

        <motion.div
          ref={ref}
          variants={container}
          initial="hidden"
          animate={mainControls}
          className="choose_us_card_item_wrapper"
        >
          {chooseUsItem &&
            chooseUsItem.map((item) => (
              <motion.div
                variants={item1}
                key={item?.id}
                className="choose_us_card"
              >
                <div className="choose_us_card_icon_wrapper">
                  <h1 className="choose_us_card_title">{item?.id}</h1>
                </div>
                <div className="choose_us_info">
                  <h5 className="choose_us_info_title">{item.title}</h5>

                  <p className="choose_us_info_des">{item.description}</p>
                </div>
              </motion.div>
            ))}
        </motion.div>
      </div>
    </div>
  );
};

export default ChooseUsSection;
